import * as React from "react"
import {useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

import AppleMusicImage from 'src/images/AppleMusic.png'
import AmazonMusicImage from 'src/images/AmazonMusic.png'
import SpotifyImage from 'src/images/Spotify.png'
import YoutubeImage from 'src/images/Youtube.png'
import TidalImage from 'src/images/Tidal.png'

const LinksPage = ({data}) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const musicIcons = [
    AppleMusicImage,
    SpotifyImage,
    YoutubeImage,
    TidalImage,
    AmazonMusicImage,
  ]

  const linkDataList: AnimatedButtonProps[] = [
    {
      backgroundImageData: data.DesktopSetup.childImageSharp.fluid,
      page: '/links/setup',
      fadeSpringClass: fadeInFromLeft,
      header: 'Setup Details',
    },
    {
      backgroundImageData: data.PianoImage.childImageSharp.fluid,
      page: '/links/music',
      fadeSpringClass: fadeInFromRight,
      header: 'Listen to my beats',
      musicIcons
    },
    {
      backgroundImageData: data.LaptopImage.childImageSharp.fluid,
      link: 'https://specranks.com',
      fadeSpringClass: fadeInFromLeft,
      header: 'SpecRanks',
      subheader: 'Under construction'
    },
  ]

  return <LinksLayout linkDataList={linkDataList}/>
}

export default LinksPage

export const query = graphql`
  query {
    AmanProfile: file(relativePath: { eq: "AmanProfile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    DesktopSetup: file(relativePath: { eq: "DeskSetup2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    LaptopImage: file(relativePath: { eq: "Laptop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    PianoImage: file(relativePath: { eq: "Piano.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    AppleMusicImage: file(relativePath: { eq: "AppleMusic.png" }) {
      childImageSharp {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    AmazonMusicImage: file(relativePath: { eq: "AmazonMusic.png" }) {
      childImageSharp {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    SpotifyImage: file(relativePath: { eq: "Spotify.png" }) {
      childImageSharp {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    YoutubeImage: file(relativePath: { eq: "Youtube.png" }) {
      childImageSharp {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    TidalImage: file(relativePath: { eq: "Tidal.png" }) {
      childImageSharp {
        fluid(maxHeight: 24, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`